<template>
  <base-template rgb="255,255,255" use-bstyle>
    <div class="gcash-box">
      <img src="~@/assets/images/stayPay/paymethod/paymaya-icon.png" alt="">
      <p class="pay-title">Paymaya {{ $t('userCenter.paymentMethod') }}</p>
    </div>
  </base-template>
</template>

<script>

export default {
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.gcash-box{
  position: relative;
  .pay-title{
    position: absolute;
    font-size: 32px;
    font-weight: 500;
    color: #005FE9;
    line-height: 48px;
    top: 308px;
    width: 100%;
    text-align: center;
  }
}

:deep(.child-title){
  display: none;
}
:deep(.headerbase){
  background: rgba(0, 95, 233, 0);
}
:deep(.is-content){
  background: #005FE9FF;
  border-radius: 0;
  margin-top: -88px;
  img{
    width: 100%;
  }
}
:deep(.headerbase .headerpulic-left){
  background: url('~@/assets/images/public/head-back-icon.png') no-repeat center center;
  background-size: 100%;
}
</style>
